/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.min.js";

// Qui sotto jQuery 
const $ = require("jquery")

export const onRouteUpdate = () => {
    
  $(document).ready(function () {
    /**-----------------------------
    *  Navbar fix
    * ---------------------------*/
    $(document).on('click', '.navbar-area .navbar-nav li.menu-item-has-children>a', function (e) {
        e.preventDefault();
    })

    /*-------------------------------------
    menu
    -------------------------------------*/
    $('.menu').click (function(){
    $(this).toggleClass('open');
    });

    // mobile menu
    if ($(window).width() < 992) {
    $(".in-mobile").clone().appendTo(".sidebar-inner");
    $(".in-mobile ul li.menu-item-has-children").append('<i class="fas fa-chevron-right"></i>');
    $('<i class="fas fa-chevron-right"></i>').insertAfter("");

    $(".menu-item-has-children a").click(function(e) {
        $(this).siblings('.sub-menu').animate({
            height: "toggle"
        }, 300);
    });
    }

    var menutoggle = $('.menu-toggle');
    var mainmenu = $('.navbar-nav');

    menutoggle.on('click', function() {
    if (menutoggle.hasClass('is-active')) {
        mainmenu.removeClass('menu-open');
    } else {
        mainmenu.addClass('menu-open');
    }
    });

    $(window).on("scroll", function() {
    var mainMenuTop = $(".navbar-area");
    if ($(window).scrollTop() >= 1) {
    mainMenuTop.addClass('navbar-area-fixed');
    }
    else {
    mainMenuTop.removeClass('navbar-area-fixed');
    }

    var ScrollTop = $('.back-to-top');
    if ($(window).scrollTop() > 1000) {
    ScrollTop.fadeIn(1000);
    } else {
    ScrollTop.fadeOut(1000);
    }
    });

  });
}